import React from 'react';
import portrait from '../assets/portrait.JPG';
import { MdMail } from 'react-icons/md';
import { SiLinkedin, SiGithub } from 'react-icons/si';

const Home = () => {
    return (
        <div className="home-container">
            <div className="initial-view">
                <div className="info-container">
                    <div className="portrait-container">
                        <img src={portrait} alt="Self portrait" />
                        <div className="contact-content">
                            <a href="mailto:kristianteppan@outlook.com"><MdMail className="contact-icon" /></a>
                            <a href="https://www.linkedin.com/in/kristian-teppan-2a3340228/" target="_blank" rel="noreferrer"><SiLinkedin className="contact-icon" /></a>
                            <a href="https://github.com/kristtep" target="_blank" rel="noreferrer"><SiGithub className="contact-icon" /></a>
                        </div>
                    </div>
                    <div className="short-intro">
                        <h1>Hello, my name is Kristian Teppan</h1>
                        <p>Full-stack developer from Norway</p>
                    </div>
                </div>
                <div className="about-me-link">
                    <a href="#/#about_me"><b>About me</b></a>
                </div>
            </div>
            <div id="/#about_me" className="bio-container">
                <div className="bio-background"></div>
                <p>My name is Kristian Teppan and I am currently studying web development at NTNU Gjøvik.
                    I have learned about front-end and back-end development, and I am now writing my bachelor's thesis for Sykehuset Innlandet.</p>
                <p>I am interested in photography and I have a drone (DJI Mavic Air) which I like to use to get a different view.
                    I enjoy hikes in the Norwegian wilderness and I love music, especially hardstyle which is my favorite genre.
                    I watch formula 1 and my favorite team is Mercedes, which unfortunately did not do well in the first race of the season.
                    Luckily, they still have time to improve the performance of the car.
                </p>
                <p>
                    Some of my other hobbies are fixing my own car and sometimes I help my friends with their cars.
                    I like to fix things myself because I learn a lot from it and get a feeling of accomplishment.
                    It's also a bonus that I save a lot of money by doing it myself.
                    I also like to work out, but prefer weightlifting over cardio.
                </p>
                {/* <p>&#9888;This website is an ongoing project and will be updated continuously.&#9888;</p> */}
            </div>

        </div>
    )
}

export default Home;