import React from 'react';
import { Link } from 'react-router-dom';
import planteskolen from '../assets/planteskolen-logo.png';
import workout from '../assets/workout.png';
import previs from '../assets/previs.png';
import summer3 from '../assets/summer-3.gif';

const Portfolio = () => {

    let boxStyle = {
        textDecoration: "none",
        color: "black",
        hover: "blue"
      };

    return (
        <div className="portfolio-container">
            <h1>My projects</h1>
            <div className="projects-container">
                <Link to="/portfolio/previs" style= {boxStyle}>
                    <div className="project-box">
                        <div className="head">
                            <h2>PreViS</h2>
                            <img src={previs} alt="ambulance icon" />
                        </div>
                        <div className="text">
                            <p>Bachelors project working with Sykehuset Innlandet on a streaming application to be used by doctors to help paramedics assess patients in ambulances.</p>
                        </div>
                    </div>
                </Link>
                <Link to="/portfolio/planteskolen" style= {boxStyle}>
                    <div className="project-box">
                        <div className="head">
                            <h2>Planteskolen</h2>
                            <img src={planteskolen} alt="Plant icon" />
                        </div>
                        <div className="text">
                            <p>Fullstack project working with NTNUs Department of Design to create an application for keeping track of location and watering schedule for the plants on campus.</p>
                        </div>
                    </div>
                </Link>
                <Link to="/portfolio/workoutplan" style= {boxStyle}>
                    <div className="project-box">
                        <div className="head">
                            <h2>7 minute workout</h2>
                            <img src={workout} alt="workout plan logo"/>
                        </div>
                        <div className="text">
                            <p>Workout project from a course on accessability and usability where our group created a page to select some exercises to perform.</p>
                        </div>
                    </div>
                </Link>
                <Link to="/portfolio/drone" style= {boxStyle}>
                    <div className="project-box">
                        <div className="head">
                            <h2>Drone pictures</h2>
                            <img src={summer3} alt="Knutshøe nature"/>
                        </div>
                        <div className="text">
                            <p>Some pictures I have taken with my DJI Mavic Air drone from various times of the year.</p>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Portfolio;