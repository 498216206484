import React from 'react';
import { NavLink } from 'react-router-dom';


const Navbar = () => {

    let activeStyle = {
        textDecoration: "underline",
        textDecorationThickness: "3px",
        color: "rgb(24, 77, 252)"
      };

    return (
        <>
        <div className="navbar-overall">
            
            <div className="navbar-container">
                <li>
                    <NavLink to="/" style={({ isActive }) => isActive ? activeStyle : undefined }>Home</NavLink>
                </li>
                <li>
                    <NavLink to="/resume" style={({ isActive }) => isActive ? activeStyle : undefined }>Resume</NavLink>
                </li>
                <li>
                    <NavLink to="/portfolio" style={({ isActive }) => isActive ? activeStyle : undefined }>Portfolio</NavLink>
                </li>
            </div>
            <div><hr /></div>
            
        </div>
        
        </>
    )
}

export default Navbar;