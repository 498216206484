import React from 'react';
import { Link } from 'react-router-dom';
import planteskolen from '../assets/planteskolen-main.jpg';
import planteskolenResult from '../assets/planteskolen-result.jpg';
import planteskolenPurpose from '../assets/planteskolen-purpose.jpg';
import planteskolenStrengths from '../assets/planteskolen-strengths.png';
import lightbulb from '../assets/lightbulb.png';
import planteskolenContribution from '../assets/planteskolen-contribution.png';
import reflection from '../assets/reflection.png';
import toTop from '../helperFunc';

const Planteskolen = () => {

    return (
        <div className="project-site-container">
            <Link to="/portfolio">&#8592; Back</Link>
            <div className="project-site-header">
                <h1>Planteskolen</h1>
            </div>
            <div className="project-site-intro">
                <p>Planteskolen is a website to keep track of all your plants!</p>
                <a id="link-to-project" href="http://planteskolen.herokuapp.com/" target="_blank" rel="noreferrer">View application</a>
                <img src={planteskolen} alt="planteskolen project landing page" />
            </div>
            <div className="project-site-content">
                <hr />
                <h2>Purpose</h2>
                <div className="project-site-purpose">
                    <div className="project-site-text">
                        <p>
                            The purpose of this project was to create an application to keep track of all the plants on campus. It needed to contain information on each plant, such as their location, water schedule, and fertilizing schedule. 
                            The application is meant to make it easy for the user to water the plants, move them, and fertilize them. 
                        </p>
                        <p>
                            The project owner gave the group information on how she would like the application to work. This was important to keep in mind when developing, because
                            she was the one who were going to be using the web application to water the plants and have an overview of their location.
                        </p>
                    </div>
                    <img src={planteskolenPurpose} alt="plant card showing plant information" />
                </div>
                <hr />
                <h2>Strengths and weaknesses</h2>
                <div className="project-site-strengths">
                    <div className="project-site-text-reverse">
                        <h3>Strengths</h3>
                        <ul>
                            <li>Password protected login</li>
                            <li>Three levels of authentication</li>
                            <li>Clear overview of which plants need water today</li>
                        </ul>
                        <h3>Weaknesses</h3>
                        <ul>
                            <li>Some flaws in the back end private routes</li>
                            <li>Aesthetics</li>
                            <li>Plant overview is overwhelming when many plants need water that day</li>
                        </ul>
                    </div>
                    <img src={planteskolenStrengths} alt="planteskolen plant overview page" />
                </div>
                <hr />
                <h2>My contribution</h2>
                <div className="project-site-my-contribution">
                    <div className="project-site-text">
                        <p>My contribution to this project was both on the back end and the front end of the application, but my main responsibility was the back end.
                            This involved the communication with the database, to store and retrieve data about the plants and the users. It also involved making the API between the front end and the back end, 
                            allowing the input data from the user on the front end to be sent to the back end, and then to the database for storage.
                            On the front end I contributed with the protected routes for the different users, and storing and handling the authentication of the users.
                        </p>
                        <p>
                            I was assigned responsibility for the back end because I had the best understanding of how it worked, but I received a lot of assistance from my group members, 
                            which made it a lot easier for me. I believe my contributions to this project are critical to the application's functionality, particularly its security, and I am pleased with my efforts.
                            The picture shows one of the functions from the back end. This function was to store the created plant in the database after checking it does not already exist.
                        </p>
                    </div>
                    <img src={planteskolenContribution} alt="a code snippet of the back-end function for creating a plant" />
                </div>
                <hr />
                <h2>Result</h2>
                <div className="project-site-result">
                    <div className="project-site-text-reverse">
                        <p>
                            The result of this project was a web application for keeping track of all the plants on campus. The application has three types of users, anonymous, gardener, and manager. 
                            The users have different levels of authenitcation where anonymous users are the most limited. Anonymous users can only view the plants and flag them, e.g. if they need water or other care. 
                            Gardeners can also view the plants, but also water them and move them if they need it. They can also edit their name and surname. Finally, the managers can do everything and this include  
                            creating or deleting users and editing their information, except their passwords. They can also create or delete plants and edit their information on where they are, how often they should be watered and when they should be fertilized.    
                        </p>
                        <p>
                            The picture shows the plant overview from the manager users perspective.
                        </p>
                    </div>
                    <img src={planteskolenResult} alt="planteskolen finished application" />
                </div>
                <hr />
                <h2>Future improvements</h2>
                <div className="project-site-improvements">
                    <div className="project-site-text">
                        <p>Some future improvements for the planteskolen application:</p>
                        <ul>
                            <li>Better design on plant overview</li>
                            <li>Security improvements in the back end</li>
                            <li>Accessability</li>
                        </ul>
                        <p>
                            An improvement to be made regarding accessability is for the anonymous users who wants to view the plants on campus. 
                            This page could be a lot more accessable by using tabindex on the elements, making the navigation much easier for people with limited abilities.
                            Some of the text on the page is not satisfactory of the WCAG level AAA, like the text saying what plant needs water today. 
                            This can easily be improved by increasing the font size, or changing the font color to increase the contrast, making it more accessable.
                        </p>
                        <p>
                            The aesthetics on this application could also be improved, because some of the pages are a bit messy and overwhelming. 
                            The plant overview is a good example of this. The first thing displayed on the plants overview is a text which informs which plants needs water and fertilizer today.
                            When there is a lot of plants that needs water, the information box gets very overwhelming with a lot of text and information. A way to improve this would be to change 
                            the layout, so that when there is more than three or four plants it just informs you of the total amount of plants that needs water, instead of showing all the individual plant names.
                        </p>
                        <p>
                            The back end private routing for the editing of the plants is currently only protected via the front end. This should also be improved to prevent any unwanted changes to the plants. 
                            The reason for this flaw is that in order for the anonymous users to flag the plants, they need access to its data, because the flags are stored in the plant object in the database. 
                            This could be improved by making a separate storing for the flags outside the plant objects, where the flag pointed to a specific plant. This would remove the security flaw and work just fine, but we did not have time to implement this in the solution.
                        </p>
                    </div>
                    <img src={lightbulb} alt="lightbulb" />
                </div>
                <hr />
                <h2>Reflection</h2>
                <div className="project-site-reflection">
                    <div className="project-site-text-reverse">
                        <p>
                            The reason I included this project in my portfolio is because it is the most comprehensive fullstack project I have worked on. It includes login with password and different levels of authentication, which is relevant features in most of today's web applications. 
                            The project also has focus on security, which is a high priority in todays society regarding hacking and unwanted access to private information.
                        </p>
                        <p>
                            If I could start the project over, I would get more information from the project owner earlier, in order to make a design proposal with all the functionalities wanted as early as possible. 
                            I would also have more user testing in order to find design flaws and general errors, and correcting them in the stages of high fidelity prototyping and early development. This could have given more time 
                            to implement some additional features, like a notification system which sends out an email when the plant is overdue for a watering. We did something similar on the forgot password feature, using a node package called nodemailer, which automatically sent out an email when the user requested a new password.
                            I am confident we could have made a similar feature for a notification system. 
                            Another additional feature we could have added if the initial phases of the project had been more efficient, is a list of people who previuosly have watered the plant. This could have been added as a value in the plant objects which is stored on the database, and could have been displayed together with all the other plant information.
                        </p>
                    </div>
                    <img src={reflection} alt="man thinking" />
                </div>
                <div className="scroll-to-top-link">
                <Link to="/portfolio/planteskolen" onClick={toTop} >
                    <div className="scroll-to-top-box">
                        <p>&#8593;</p>
                        <p>Go to top</p>
                    </div>
                </Link>
                </div>
            </div>
        </div>
    )
}

export default Planteskolen;