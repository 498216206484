import React from 'react';

const Footer = () => {
    return (
        <div className="footer-container">
            <p>Made by Kristian Teppan &copy;</p>
        </div>
    )
}

export default Footer;