import React from 'react';
import { Link } from 'react-router-dom';
import previsPurpose from '../assets/previs-purpose.jpg';
import previs from '../assets/previs-main.jpg';
import previsStrengths from '../assets/previs-strengths.jpg';
import previsContribution from '../assets/previs-contribution.jpg';
import previsResult from '../assets/previs-result.jpg';
import lightbulb from '../assets/lightbulb.png';
import reflection from '../assets/reflection.png';
import toTop from '../helperFunc';

const Previs = () => {

    return (
        <div className="project-site-container">
            <Link to="/portfolio">&#8592; Back</Link>
            <div className="project-site-header">
                <h1>PreViS</h1>
            </div>
            <div className="project-site-intro">
                <p>
                    PreViS was a group project on making an application for streaming video and audio using WebRTC technology. 
                </p>
                <a id="link-to-project" href="https://github.com/kristtep/bachelor_2022" target="_blank" rel="noreferrer">View source code</a>
                <img src={previs} alt="previs project illustration in ambulance" />
            </div>
            <div className="project-site-content">
                <hr />
                <h2>Purpose</h2>
                <div className="project-site-purpose">
                    <div className="project-site-text">
                        <p>
                            The purpose of this project was to help Sykehuset Innlandet with an ongoing project called PreViS, which is a project to send video streams from ambulances to hospitals in order to help 
                            paramedics on their assessment of the patients. More specifically, our project focused on how we could design and develop a prototype application for sending these video and audio streams.
                            Our focus was to make it as easy and simple as possible to use, to prevent any focus being removed from the patients. 
                        </p>
                        <p>
                            The idea behind this application is to help the paramedics assess the 
                            patients by having a doctor with them virtually through the streams. This helps patients get better and faster treatment, especially if they live far away from a hospital. In critical situations 
                            this could be a life-saving idea, which would help improve quality of treatment in less populated areas.  
                        </p>
                    </div>
                    <img src={previsPurpose} alt="prototype of the interface from ambulance side" />
                </div>
                <hr />
                <h2>Strengths and weaknesses</h2>
                <div className="project-site-strengths">
                    <div className="project-site-text-reverse">
                        <h3>Strengths</h3>
                        <ul>
                            <li>Developed based on paramedics and doctors feedback</li>
                            <li>Easy to use</li>
                            <li>Minimalistic design</li>
                        </ul>
                        <h3>Weaknesses</h3>
                        <ul>
                            <li>Not tested in ambulance</li>
                            <li>Unable to add more streams after calling</li>
                            <li>Not optimal performance</li>
                        </ul>
                    </div>
                    <img src={previsStrengths} alt="previs in call from hospital side" />
                </div>
                <hr />
                <h2>My contribution</h2>
                <div className="project-site-my-contribution">
                    <div className="project-site-text">
                        <p>
                            My role in this project was development manager, meaning I had the responsibility of delegating development tasks, and making sure the coding of the application got done on time.
                            I also made the signaling server for this project, which was an express server which had to communicate with the web application in order for the users to find each other and establish a peer connection.
                            The signaling server uses a technology called Socket.io, which is a library that allows bi-directional and low latency communication between a client and a server. It creates a room for the users to share information, 
                            making it possible for the peer connection to send an offer and recieve an answer, thus establishing a successful connection on which the WebRTC technology can send and recieve media.
                        </p>
                        <p>
                            The picture shows the back end signaling server, which was pretty simple and straightforward, but had a cruicial role in this project because without it the users would not find each other and the connection would not be made.
                        </p>
                    </div>
                    <img src={previsContribution} alt="back end signal server code using socket technology" />
                </div>
                <hr />
                <h2>Result</h2>
                <div className="project-site-result">
                    <div className="project-site-text-reverse">
                        <p>
                            The result of this project was a React application using WebRTC technology to stream video and audio from one user to another.
                            Upon entering the application you first choose if you are in the ambulance or in the hospital. The ambulance side will enter a page which will fetch your cameras video feed and your microphones audio. 
                            By pressing the buttons for ultrasound or thermal camera you get a screenshare pop-up from your browser and this is to simulate the different functionalities. After you added the cameras you need, 
                            you can call the wanted location, like a hospital or emergency room.
                        </p>
                        <p>
                            On the hospital side you need to only share audio and the screen you are using the application on. The shared screen from the hospital will be sent to the ambulance, in order for the paramedics to see 
                            what the doctor is looking at. This will prevent any confusion on what is being discussed about the patient. When the ambulance call the hospital, an answer button will appear on the screen together with 
                            a ring tone for notifying the doctor about the incoming call. When the doctor answers, the video and audio from the ambulance will appear and the screen and audio from the hospital will be sent to the ambulance. 
                            The call has started and the two users can easily communicate by talking to each other and looking at the exact same video.
                        </p>
                        <p>
                            The picture shows the view from the ambulance, where you can see the shared screen from the hospital.
                        </p>
                    </div>
                    <img src={previsResult} alt="previs in call from ambulance side" />
                </div>
                <hr />
                <h2>Future improvements</h2>
                <div className="project-site-improvements">
                    <div className="project-site-text">
                        <p>Our application have some room for improvement and is not perfect in many ways. Some points to be improved is: </p>
                        <ul>
                            <li>Testing in ambulance</li>
                            <li>Adding new stream after peer connection is established</li>
                            <li>Increase the stability of the connection</li>
                        </ul>
                        <p>
                            In order to increase the stability of the connection, we could use a different kinds of STUN and TURN servers. These servers are used by the WebRTC technology to send the packets of data, and we used a free service which offered these with login credentials.
                            However, these free servers were limited to 500 MB of bandwith, which could quickly become an issue when sending high quality video.
                            Changing these to some higher quality servers with greater bandwith would be a big improvement, and might only be a question of price.
                        </p>
                        <p>
                            Another improvement which should be made is being able to add a new video stream after the call has been made and the connection is established. We where not able to solve this issue, but 
                            I think this functionality could be added if we had a better understanding of the peer connection and how it sends the streams. With some more time I think we could make this improvement by 
                            getting the syntax right for adding the new stream directly to the existing peer connection, and maybe use something different than the UseRef hook from React to store the peer connection in. 
                        </p>
                    </div>
                    <img src={lightbulb} alt="lightbulb" />
                </div>
                <hr />
                <h2>Reflection</h2>
                <div className="project-site-reflection">
                    <div className="project-site-text-reverse">
                        <p>
                            I chose to include this project because it is the most exciting project during the bachelors degree.
                            We were able to work with Sykehuset Innlandet on a project which is planned to be used in the future on actual patients, which is unlike anything I have worked on before.
                            We also got to see the progress in their prototyped ambulance, and have meetings and interviews with involved paramedics and doctors which is going to be the main users of the application.
                            The most exciting part of this project is that our ideas might be used in the finished product, which can help save lives.
                        </p>
                        <p>
                            If I could start this project from scratch again, I would have set aside more time to test the application on the target group, which was paramedics and doctors. This could have helped 
                            on further improving the user interface of the application and given a better understanding on which aspects should be changed or improved. 
                        </p>
                    </div>
                    <img src={reflection} alt="man thinking" />
                </div>
                <div className="scroll-to-top-link">
                <Link to="/portfolio/previs" onClick={toTop} >
                    <div className="scroll-to-top-box">
                        <p>&#8593;</p>
                        <p>Go to top</p>
                    </div>
                </Link>
                </div>
            </div>
        </div>
    )
}

export default Previs;