import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import Previs from './components/Previs';
import Planteskolen from './components/Planteskolen';
import Workoutplan from './components/Workoutplan';
import Drone from './components/Drone';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="overall-container">
      <HashRouter>
        <ScrollToTop />
        <header>
          <Navbar />
        </header>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/resume" element={<Resume />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/portfolio/previs" element={<Previs />} />
          <Route exact path="/portfolio/planteskolen" element={<Planteskolen />} />
          <Route exact path="/portfolio/workoutplan" element={<Workoutplan />} />
          <Route exact path="/portfolio/drone" element={<Drone />} />
        </Routes>
        <footer>
          <Footer />
        </footer>
      </HashRouter>
    </div>
  );
}

export default App;
