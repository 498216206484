import React from 'react';
import { Link } from 'react-router-dom';
import lightbulb from '../assets/lightbulb.png';
import workout from '../assets/workout-main.png';
import workoutPurpose from '../assets/workout-purpose.png';
import workoutStrengths from '../assets/workout-strengths.png';
import workoutContribution from '../assets/workout-contribution.png';
import workoutResult from '../assets/workout-result.png';
import reflection from '../assets/reflection.png';
import toTop from '../helperFunc';

const Workoutplan = () => {

    return (
        <div className="project-site-container">
            <Link to="/portfolio">&#8592; Back</Link>
            <div className="project-site-header">
                <h1>7 minute workout</h1>
            </div>
            <div className="project-site-intro">
                <p>7 minute workout is a site to keep anyone in shape anywhere they are!</p>
                <a id="link-to-project" href="https://github.com/kristtep/accessible-workout-project" target="_blank" rel="noreferrer">View source code</a>
                <img src={workout} alt="seven minute workout page" />
            </div>
            <div className="project-site-content">
                <hr />
                <h2>Purpose</h2>
                <div className="project-site-purpose">
                    <div className="project-site-text">
                        <p>
                            The purpose of this project was to make a workout website for anyone, regardless of their abilities. Our group got a list of personas which all had their own struggles, 
                            which we used to make the workout site as accessable and usable as possible. The website should carry out a seven minute long workout with some selectable excercises, with 30 seconds of excercise and 5 seconds of resting in between. 
                            The website needed to be informative and should guide the user through all of the different steps when using the site. 
                        </p>
                    </div>
                    <img src={workoutPurpose} alt="half way through excercise" />
                </div>
                <hr />
                <h2>Strengths and weaknesses</h2>
                <div className="project-site-strengths">
                    <div className="project-site-text-reverse">
                        <h3>Strengths</h3>
                        <ul>
                            <li>Accessable</li>
                            <li>Usable</li>
                            <li>Error prevention</li>
                        </ul>
                        <h3>Weaknesses</h3>
                        <ul>
                            <li>Aesthetics</li>
                            <li>Limited information shown on screen when workout starts</li>
                            <li>No storing of previous workouts</li>
                        </ul>
                    </div>
                    <img src={workoutStrengths} alt="example of error prevention on the website" />
                </div>
                <hr />
                <h2>My contribution</h2>
                <div className="project-site-my-contribution">
                    <div className="project-site-text">
                        <p>
                            My contribution in this project was mostly JavaScript functions which made the functionality possible. All the group members contributed in making the site as accessable and usable as possible, through semantic HTML tags, tabindex, and informative elements throughout the site.

                        </p>
                        <p>
                            The picture shows a snippet of code from the functions file from this project. The first function is for printing the excercise information to the HTML document from an array of excercise objects.
                            The second function is for error prevention, if the user has not selected any excercises, there will appear an alert to the user which says "Please select excercises.". The last function is a simple function to play the rest audio.
                        </p>
                    </div>
                    <img src={workoutContribution} alt="code snippet of some JavaScript functions" />
                </div>
                <hr />
                <h2>Result</h2>
                <div className="project-site-result">
                    <div className="project-site-text-reverse">
                        <p>
                            The result of this project was a one-page website which allows you to choose between different workouts and select which ones you would like.
                            There are options to exclude certain muscle groups for people struggling with some of their muscles, or if they just don't want to excercise certain muscle groups.
                            The user can select the duration of the workout, may it be longer or shorter than the default seven minutes. The amount of selected excercises will then be divided by the total duration, 
                            which will decide the length of each excercise. There is also added some time in between the excercises for resting.
                        </p>
                        <p>
                            During the workout, there will be a progress bar to show how far along your workout is. There is also sound samples playing for each excercise in order to make it more accessable for people with limited vision.
                            The excercise will be displayed with an illustration, information on the targeted muscles, and a description on the bottom on how to do the excercise.
                        </p>
                    </div>
                    <img src={workoutResult} alt="7 minute workout individual excercise page" />
                </div>
                <hr />
                <h2>Future improvements</h2>
                <div className="project-site-improvements">
                    <div className="project-site-text">
                        <p>Improvements to be made on this project:</p>
                        <ul>
                            <li>Better display of excercise information</li>
                            <li>Aesthetics</li>
                            <li>Storing of previous workouts</li>
                        </ul>
                        <p>
                            The display of the excercise information is a potential future improvement, because as of today, the website does not display all the information in the viewed portion of the site.
                            All that shows when beginning the workout is the progress bar, remaining time, what excercise, targeted muscles, and the illustration. All the information about how to do the excercise is hidden further down the page.
                            This could be improved by changing the layout. For example, the progress bar could be drastically smaller and the step by step guide could be placed beside the illustration instead of below it. This would 
                            make the excercise pages more clear and the need to scroll would be eliminated, making the page even more usabale.
                        </p>
                        <p>
                            Another possible improvement is to add functionality to store previous workouts. This could make the site more attractive, as it can give more motivation to continue using it when you can see 
                            how much you have worked out in the past. A way of solving this would be to connect the page to a database like MongoDB, where the workouts could be stored and fetched upon a request from the user, when they want to see their previous workouts.
                        </p>
                    </div>
                    <img src={lightbulb} alt="lightbulb" />
                </div>
                <hr />
                <h2>Reflection</h2>
                <div className="project-site-reflection">
                    <div className="project-site-text-reverse">
                        <p>
                            I included this project in my portfolio on the basis of accessability and usability being an important aspect of web development.
                            I am also proud of all the functions I have written in this project, like how the progress bar calculates the total progression of the workout. The project required a lot of logical thinking and problem solving which I enjoyed.
                        </p>
                        <p>
                            If I could do this project again, I would use React as a framework to create a component for the displayed excercise. Instead of rendering the main page with the excercise page on top as a pop-up, I would render only the React component, which would decrease the amount being rendered, thus making it a bit faster.
                            I would also connect the application to a MonogDB database, for storing the workouts, and allowing the user to see their previous workouts in another React component. These improvements could make the usability better as they could make the site faster and more user friendly. 
                        </p>
                    </div>
                    <img src={reflection} alt="man thinking" />
                </div>
                <div className="scroll-to-top-link">
                <Link to="/portfolio/workoutplan" onClick={toTop} >
                    <div className="scroll-to-top-box">
                        <p>&#8593;</p>
                        <p>Go to top</p>
                    </div>
                </Link>
                </div>
            </div>
        </div>
    )
}

export default Workoutplan;