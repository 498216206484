import React from 'react';

const Resume = () => {
    return (
        <div className="resume-container">
            <div className="resume-contactinfo">
                <h1>Kristian Teppan</h1>
                <address>
                    <p>Address: Søndre Ålsvegen 527, 2750 Gran</p>
                    <p>Phone number: +47 915 61 920 | email: <a href = "mailto:kristianteppan@outlook.com">kristianteppan@outlook.com</a></p>
                    <p>Date of birth: 11<sup>th</sup> October 1997</p>
                </address>
            </div>
            <div className="resume-education">
                <h2>Education</h2>
                <div className="resume-infobox">
                    <div className="date">
                        <p>August 2019 &mdash; todays date</p>
                    </div>
                    <div className="description">
                        <b>NTNU Gjøvik</b>
                        <p>Bachelor in web development, graduating in June 2022. Learned about back-end and front-end development, design principles and methods, and protoyping.</p>
                    </div>
                </div>
                <div className="resume-infobox">
                    <div className="date">
                        <p>August 2013 &mdash; June 2016</p>
                    </div>
                    <div className="description">
                        <b>Hadeland High School</b>
                        <p>General study with specialization in: IT, physics, chemistry and mathematics.</p>
                    </div>
                </div>
            </div>
            <div className="resume-experience">
                <h2>Experience</h2>
                <div className="resume-infobox">
                    <div className="date">
                        <p>August 2016 &mdash; December 2021</p>
                    </div>
                    <div className="description">
                        <b>Mechanical assembly, Tronrud Engineering AS</b>
                        <p>Worked here before and after my time in the military and every summer since 2018. My tasks was mechanical assembly, machining, and logistics.</p>
                    </div>
                </div>
                <div className="resume-infobox">
                    <div className="date">
                        <p>January 2018 &mdash; March 2018</p>
                    </div>
                    <div className="description">
                        <b>Snow removal with tractor, Alm Anlegg</b>
                        <p>Cleaning snow with plow on tractor from different addresses in Oslo.</p>
                    </div>
                </div>
                <div className="resume-infobox">
                    <div className="date">
                        <p>January 2017 &mdash; December 2017</p>
                    </div>
                    <div className="description">
                        <b>Vehicle maintenance, His Majesty The King's Guard</b>
                        <p>Served in The Royal Guard's fourth rifle company. In charge of the company's four trucks, eight cars, and four trailers. Responsibilities was transportation, maintenance, lending, and reporting errors on the vehicles.</p>
                    </div>
                </div>
                <div className="resume-infobox">
                    <div className="date">
                        <p>June 2016 &mdash; August 2016</p>
                    </div>
                    <div className="description">
                        <b>Translation, Andritz Hydro AS</b>
                        <p>Translated operation and maintenance manuals for generator and main inlet valve in hydropower plant, from English to Norwegian.</p>
                    </div>
                </div>
                <div className="resume-infobox">
                    <div className="date">
                        <p>May 2014 &mdash; December 2014</p>
                    </div>
                    <div className="description">
                        <b>Extra help, Hadeland Flissenter AS</b>
                        <p>Extra help in the store. My tasks was mixing paint, customer service, operating the cash register, and refilling of goods.</p>
                    </div>
                </div>
            </div>
            <div className="resume-skills">
                <h2>Skills</h2>
                <div className="resume-infobox">
                    <div className="date">
                        <b>Languages</b>
                    </div>
                    <div className="description">
                        <b>Norwegian</b>
                        <p>Native</p>
                        <b>English</b>
                        <p>Fluent</p>
                    </div>
                </div>
                <div className="resume-infobox">
                    <div className="date">
                        <b>Skills</b>
                    </div>
                    <div className="description">
                        <b>HTML</b>
                        <b>CSS</b>
                        <b>JavaScript</b>
                        <b>PHP</b>
                        <b>MySQL</b>
                        <b>React</b>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resume;