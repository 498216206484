import React from 'react';
import { Link } from 'react-router-dom';
import winter1 from '../assets/winter-1.gif';
import winter2 from '../assets/winter-2.gif';
import winter3 from '../assets/winter-3.gif';
import spring1 from '../assets/spring-1.gif';
import summer1 from '../assets/summer-1.gif';
import summer2 from '../assets/summer-2.gif';
import summer3 from '../assets/summer-3.gif';
import australia1 from '../assets/australia-1.gif';
import australia2 from '../assets/australia-2.gif';

const Drone = () => {

    const fullscreen = (num) => {
        let elem = document.getElementById(`${num}`);
        if (!document.fullscreenElement) {
            elem.requestFullscreen();
        } else {
            document.exitFullscreen();
        }

    };

    return (

        <div className="drone">
            <Link to="/portfolio">&#8592; Back</Link>
            <h1>Drone pictures</h1>
            <p>
                I enjoy taking pictures in my spare time and I mostly use my DJI Mavic Air drone to do so.
                Below you can view some of my work from various times of the year.
                I like to use the drone to get a different angle on my pictures, which would not be possible from the ground.
            </p>
            <p id="fullscreen-picture-text">Click the pictures for full screen.</p>
            <div className="pictures">
                <img id="1" src={winter1} alt="winter in Gran" onClick={() => fullscreen(1)} />
                <img id="2" src={winter3} alt="winter in Gran" onClick={() => fullscreen(2)} />
                <img id="5" src={summer1} alt="summer in Gran" onClick={() => fullscreen(5)} />
                <img id="3" src={winter2} alt="winter in Gran" onClick={() => fullscreen(3)} />
                <img id="6" src={summer2} alt="summer on Skjerva" onClick={() => fullscreen(6)} />
                <img id="4" src={spring1} alt="spring in Gjøvik" onClick={() => fullscreen(4)} />
                <img id="7" src={summer3} alt="summer in Knutshøe" onClick={() => fullscreen(7)} />
                <img id="8" src={australia1} alt="Vacation in Australia" onClick={() => fullscreen(8)} />
                <img id="9" src={australia2} alt="Vacation in Australia" onClick={() => fullscreen(9)} />
            </div>
        </div>

    )
}

export default Drone;